import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
    {
    path: '',
    children: [
      {
				path: '',
				loadChildren: () => import('./routes/mint/mint.module').then(m => m.MintModule)
      },
      {
				path: 'privacy',
				loadChildren: () => import('./routes/privacy/privacy.module').then(m => m.PrivacyModule)
      },
      {
				path: 'staking',
				loadChildren: () => import('./routes/staking/staking.module').then(m => m.StakingModule)
      },
      {
				path: 'terms',
				loadChildren: () => import('./routes/terms/terms.module').then(m => m.TermsModule)
      },
      {
				path: 'mint',
				loadChildren: () => import('./routes/mint/mint.module').then(m => m.MintModule)
      },
      {
				path: 'public-sale',
				loadChildren: () => import('./routes/public-sale/public-sale.module').then(m => m.PublicSaleModule)
      },
      {
				path: 'presale-whitelist',
				loadChildren: () => import('./routes/presale-whitelist/presale-whitelist.module').then(m => m.PresaleWhitelistModule)
      },
      {
				path: 'whales-and-partners',
				loadChildren: () => import('./routes/whales-and-partners/whales-and-partners.module').then(m => m.WhalesAndPartnersModule)
      },
      {
				path: 'redeem',
				loadChildren: () => import('./routes/redeem/redeem.module').then(m => m.RedeemModule)
      },
      {
				path: 'landing',
				loadChildren: () => import('./routes/landing/landing.module').then(m => m.LandingModule)
      },
      {
				path: '**',
				redirectTo: '',
			},
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
