import { Component, HostListener, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import * as smoothscroll from "smoothscroll-polyfill";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {

  scrolled: boolean = false;
  isActive: boolean = false;

  hideHeadingItems: boolean = true;

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.scrolled = window.pageYOffset > 48;
  }

  constructor(
    private router: Router,
  ) {
    this.scrolled = window.pageYOffset > 48;
    smoothscroll.polyfill();
  }

  ngOnInit(): void {
    this.checkForRoute();
    this.listenToRouteChange();
  }

  listenToRouteChange() {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.checkForRoute();
      }
    });
  }

  checkForRoute() {
    if (this.router.url.includes('/landing')) {
        this.hideHeadingItems = false;
      } else {
        this.hideHeadingItems = true;
    }
  }

  scrollTo(elementId) {
    document.getElementById(elementId).scrollIntoView({
      behavior: 'smooth'
    });
  }

  toggle() {
    if(window.innerWidth < 1199) {
      this.isActive = !this.isActive;
    }
  }

}
