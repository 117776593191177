import { Component } from '@angular/core';
import { LangService } from './shared/services/lang.service';
import * as AOS from 'aos';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'client';

  showContent: boolean = false;

  constructor(langService: LangService) {
    langService.init();
  }

  ngOnInit() {
		AOS.init({
			duration: 1200,
      delay: 200,
		});

    setTimeout(()=> this.showContent = true, 1000);
  }


}
