<div class="loader">
  <div class="loader-wrapper">
    <div class="loader-content">
      <div class="loader-text"> LOADING ... </div>
    </div>
  </div>

  <div class="top-layer"></div>
  <div class="bottom-layer"></div>
</div>
